


















































































































































































import Vue from "vue";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseWarehouseLocation } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { getauthorities } from "@/helpers/cookie";
interface ListParent {
  parentName: string;
  id: string;
  description: string;
}
export default Vue.extend({
  data() {
    return {
      dataListWarehouseLocation: {} as ResponseWarehouseLocation,
      dataListWarehouse: {} as any,
      trigerdisable: false as boolean,
      dataListParent: [] as ListParent[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "" as string,
      authPrivilege: [] as string[],
      pageSizeSet: 10,
      typemodal: "" as string,
      loadingTable: false as boolean,
      direction: "" as string,
      titlemodalOpen: "lbl_warehouse_rack" as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "warehousebranch" }),
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      formRules: {
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_type_here",
          decorator: [
            "warehouse",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        name: {
          label: "lbl_name",
          name: "name",
          placeholder: "lbl_type_here",
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        code: {
          label: "lbl_code",
          name: "code",
          placeholder: "lbl_type_here",
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "warehousename",
          key: "warehouse",
          sorter: true,
          width: "30%",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "locationname",
          key: "location",
          sorter: true,
          width: "35%",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_code"),
          dataIndex: "code",
          key: "code",
          sorter: true,
          width: "35%",
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
    };
  },

  mounted() {
    this.getListOfWarehouseLocation("");
    this.getlistOfWarehouse();
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "warehouse-location") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: 120,
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    getlistOfWarehouse() {
      let params: any;
      params = {
        limit: 1000,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      logisticServices
        .listWarehouse(params, "")
        .then((res: any) => {
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListWarehouse = res;
        })
        .finally(() => (this.loadingTable = false));
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfWarehouseLocation("");
    },
    reponseDeleteTable(response): void {
      const path = response.data.id;
      logisticServices.deleteWarehouseLocation(path).then(() => {
        this.ResetFilter(false);
        this.getListOfWarehouseLocation("");
      });
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = "lbl_edit_warehouse_rack";
      this.dataDetail = "/" + response.data.id;
      this.ResetFilter(false);
      this.getListOfWarehouseLocation("/" + response.data.id);
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      this.page = 1;
      if (getlist) this.getListOfWarehouseLocation("");
    },
    getListOfWarehouseLocation(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      logisticServices
        .listWarehouseLocation(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.map(
              (dataObject, index) => (
                (dataObject.key = index),
                (dataObject.warehousename = dataObject.warehouse.name),
                (dataObject.locationname = dataObject.name)
              )
            );
            this.dataListWarehouseLocation = res;
          } else {
            const checkExist = this.dataListWarehouse["data"].findIndex(
              (item) => item.id === res.warehouse.id
            );
            if (checkExist == -1)
              this.dataListWarehouse["data"].push({
                id: res.warehouse.id,
                name: res.warehouse.name,
              });
            const idtimeout = setTimeout(() => {
              this.form.setFieldsValue({
                warehouse: res.warehouse.id,
                name: res.name,
                code: res.code
              });
              clearTimeout(idtimeout);
            }, 200);
            this.modalOpen = true;
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    reponseSearchInput(response): void {
      if (response) this.search = `name~*${response}*_OR_warehouse.name~*${response}*_OR_code~*${response}*`;
      else this.search = "";
      this.page = 1;
      this.getListOfWarehouseLocation("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfWarehouseLocation("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfWarehouseLocation("");
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.form.resetFields();
      this.titlemodalOpen = "lbl_create_new_location_rack";
      this.ResetFilter(false);
    },
    createNewWarehouse(datapost): void {
      logisticServices
        .createWarehouseLocation(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfWarehouseLocation("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateWarehouse(datapost): void {
      logisticServices
        .updateWarehouseLocation(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfWarehouseLocation("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e: Event, type: string | "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datapost = {
            warehouseId: values.warehouse,
            name: values.name,
            code: values.code
          };
          this.isFormSubmitted = true;
          if (type === "create") {
            this.createNewWarehouse(datapost);
          } else {
            this.updateWarehouse(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
