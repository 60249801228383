// Core
import { AxiosError} from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel , RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ResponseWarehouse,
  ResponseListOfInventory,
  ResponseWarehouseBranch,
  RequestWarehouseBranch,
  RequestWarehouseLocation,
  ResponseWarehouseLocation,
  RequestWarehouse,
  ResponseListProduct,
  ResponseReportValuation,
  ResponseDetailProduct,
  ResponseGetListWorkOrder,
  ResponseWorkOrderResult,
  ResponseWorkOrderDetail,
  RequestResultWorkOrder,
  ResponseResultWorkOrder,
  ResponseWorkOrderResultDetail
} from "@/models/interface/logistic.interface";

export class LogisticServices extends HttpClient {
  public constructor() {
    super();
  }

  // irfan
  listProduct = (params: RequestQueryParamsModel) :Promise<ResponseListProduct> => {
    return this.get<ResponseListProduct>(Api.Product , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetailProduct = (id: string) :Promise<ResponseDetailProduct> => {
    return this.get<ResponseDetailProduct>(Api.Product + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getReportValuation = (params: RequestQueryParamsModel, asOfDate: string) :Promise<ResponseReportValuation> => {
    return this.get<ResponseReportValuation>(Api.ReportValuation + `/${asOfDate}` , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDownloadValuation = (params: RequestQueryParamsModel, asOfDate: string) :Promise<Blob> => {
    return this.get<Blob>(Api.ReportValuation + `/${asOfDate}/download` , { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getPrintValuation = (params: RequestQueryParamsModel, asOfDate: string) :Promise<Blob> => {
    return this.get<Blob>(Api.ReportValuation + `/${asOfDate}/print` , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getListResultWorkOrder = (params: RequestQueryParamsModel): Promise<ResponseWorkOrderResult> => {
    return this.get<ResponseWorkOrderResult>(Api.Workorder + "/result" , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getResultWorkOrderDetail = (params: RequestQueryParamsModel, id: string): Promise<ResponseWorkOrderResultDetail> => {
    return this.get<ResponseWorkOrderResultDetail>(Api.Workorder + `/result/${id}` , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getListWorkOrder = (params: RequestQueryParamsModel): Promise<ResponseGetListWorkOrder> => {
    return this.get<ResponseGetListWorkOrder>(Api.Workorder , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getListWorkOrderDetail = (params: RequestQueryParamsModel, id: string): Promise<ResponseWorkOrderDetail> => {
    return this.get<ResponseWorkOrderDetail>(Api.Workorder + `/${id}` , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getWorkOrderDocNo = (params: RequestQueryParamsModel): Promise<string> => {
    return this.get<string>(Api.Workorder + "/doc-no" , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  createResultWorkOrder = (payload: RequestResultWorkOrder): Promise<ResponseResultWorkOrder> => {
    return this.post<ResponseResultWorkOrder, RequestResultWorkOrder>(Api.Workorder + "/result" , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  
  public listWarehouse(params : RequestQueryParamsModel , path:string): Promise<ResponseWarehouse> {
    return this.get<ResponseWarehouse>(Api.Warehouse+path , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createWarehouse(payload: RequestWarehouse): Promise<boolean> {
    return this.post<boolean, RequestWarehouse>(Api.Warehouse , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  public closePeriodInventory(params : string): Promise<boolean> {
    return this.post<boolean , any>(Api.ClosePeriodInventory+params)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  public updateWarehouse(payload: RequestWarehouse , path:string): Promise<boolean> {
    return this.put<boolean, RequestWarehouse>(`${Api.Warehouse}/${path}` , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  public deleteWarehouse(path:string): Promise<any> {
    return this.delete<any>(`${Api.Warehouse}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public listOfInventory(params : RequestQueryParamsModel): Promise<ResponseListOfInventory> {
    return this.get<ResponseListOfInventory>(Api.InventoryLine , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
  
  public listWarehouseBranch(params : RequestQueryParamsModel , path:string): Promise<ResponseWarehouseBranch> {
    return this.get<ResponseWarehouseBranch>(Api.WarehouseBranch+path , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createWarehouseBranch(payload: RequestWarehouseBranch): Promise<boolean> {
    return this.post<boolean, RequestWarehouseBranch>(Api.WarehouseBranch , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createWorkOrder(payload: any): Promise<boolean> {
    return this.post<boolean, any>(Api.Workorder , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public updateWarehouseBranch(payload: RequestWarehouseBranch , path:string): Promise<boolean> {
    return this.put<boolean, RequestWarehouseBranch>(Api.WarehouseBranch+path , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public deleteWarehouseBranch(path:string): Promise<any> {
    return this.delete<any>(`${Api.WarehouseBranch}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public listWarehouseLocation(params : RequestQueryParamsModel , path:string): Promise<ResponseWarehouseLocation> {
    return this.get<ResponseWarehouseLocation>(Api.WarehouseLocation+path , { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public createWarehouseLocation(payload: RequestWarehouseLocation): Promise<boolean> {
    return this.post<boolean, RequestWarehouseLocation>(Api.WarehouseLocation , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public updateWarehouseLocation(payload: RequestWarehouseLocation , path:string): Promise<boolean> {
    return this.put<boolean, RequestWarehouseLocation>(Api.WarehouseLocation+path , payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public deleteWarehouseLocation(path:string): Promise<any> {
    return this.delete<any>(`${Api.WarehouseLocation}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const logisticServices = new LogisticServices();
